import React from 'react';
import './App.css';

import devynGif from './images/devyn.gif';
import logo from './images/logo.png';
import girl1 from './images/girl1.gif';


const pages = {
  VIDEOS: "videos",
  ABOUT: "about"
}


const clips = [

  { 
    id: 1, 
    filmName: "The Beautiful Future", 
    clipName: "Mood Dream", 
    caption: `This tone clip is a moving look book for the series The Beautiful Future, intended to establish the visual style and mood.`,
    clipLink: "https://player.vimeo.com/video/321591313",
   
  },

  { 
    id: 5, 
    filmName: "The Most Girl Part of You", 
    clipName: "", 
    caption: `Short film based on the story 'The Most Girl Part of You' by acclaimed American author, Amy Hempel.`, 
    clipLink: "https://player.vimeo.com/video/213581099", 
    websiteTitle: "", 
    websiteLink: "", 
    status: "In development"
  },
  { 
    id: 2, 
    filmName: "Not Waving But Drowning", 
    clipName: "Trailer",
    caption: `Trailer for the feature film, Not Waving But Drowning.`,
    clipLink: "https://www.youtube.com/embed/kZwVBMbBEC4", 
    websiteTitle: "NWBD Website", 
    websiteLink: "http://notwavingbutdrowning.com", 
    status: ""
  },
  { 
    id: 4, 
    filmName: "Not Waving But Drowning", 
    clipName: "Blonde on Blonde", 
    caption: `Selected scene from the film Not Waving But Drowning.`,
    clipLink: "https://www.youtube.com/embed/idyvgD1Ywp4", 
    websiteTitle: "", 
    websiteLink: "", 
    status: ""
  },
  { 
    id: 3, 
    filmName: "Not Waving But Drowning", 
    clipName: "Horse on a train", 
    caption: `Selected scene from the film Not Waving But Drowning.`,
    clipLink: "https://player.vimeo.com/video/213585166", 
    websiteTitle: "", 
    websiteLink: "", 
    status: "In development"
  },
  
   { 
    id: 6, 
    filmName: "Not Waving But Drowning", 
    clipName: "Proof of Concept",
    caption: `Created before production to establish the mood and visual style of the film.`,
    clipLink: "https://player.vimeo.com/video/6183559", 
    websiteTitle: "", 
    websiteLink: "", 
    status: "In development"
  },
  { 
    id: 7, 
    filmName: "Challenger", 
    clipName: "Are You Scared Too?", 
    caption: `Music Video for "Are You Scared Too?" off of the album The World is Too Much For Me by Challenger.`, 
    clipLink: "https://player.vimeo.com/video/170250783", 
    websiteTitle: "", 
    websiteLink: "", 
    status: "In development"
  },
  { 
    id: 8, 
    filmName: "Watching Me", 
    clipName: "",
    caption: `In the course of researching The Beautiful Future, I recorded my computer screen for one month. The objective was to see how I felt, and whether to not my behavior changed when I knew my digital actions were being recorded.`, 
    clipLink: "https://www.youtube.com/embed/ZkU89tFd1GY", 
    websiteTitle: "", 
    websiteLink: "", 
    status: ""
  },
  { 
    id: 9, 
    filmName: "You Will Meet the Bringer (starring Robert De Niro)", 
    clipName: "", 
    caption: `A girl out late at night walking her dog encounters a man who needs her help escaping hell.`, 
    clipLink: "https://player.vimeo.com/video/204309154", 
    websiteTitle: "", 
    websiteLink: "", 
    status: ""
  }
]


function Videos() {
  return (
    <div className="Videos">
      
      {clips.map(clip => (
        <div className="Clip">
          <iframe class="vidFrame" src={clip.clipLink} frameborder="0" allowfullscreen=""></iframe>
          <div className="Clip-Info">
          <div className="Clip-Title">
            {clip.filmName}
            &nbsp;&nbsp;<span className="Clip-ClipName">{clip.clipName}</span>
          </div>
          <div className="Clip-Caption">{clip.caption}</div>
          <div className="Clip-Website"><a href={clip.websiteLink} target="_blank">{clip.websiteTitle}</a></div>
        </div></div>
      ))}
    </div>
  );
}

function About() {
  return (
    <div className="About">
      <div className="AboutImage">
        <center><img src={devynGif} /></center>
      </div>
      <div className="AboutSide">
        <div className="AboutBio">
          Devyn Waitt is a filmmaker from the gulf coast of Florida, whose work is marked by a lyrical femininity. Her work as a director has screened at film festivals across the world including winning the Top Jury Prize at the New Horizons festival in Wroclaw, and Best Cinematography at the Napa Valley Film festival. She was a grant recipient for No Bro Zone a female filmmaker initiative from Borscht Corp, a non profit film organization in Miami. 
          <br />    <br />

          She received her BFA from the school of Motion Picture, Television and Recording Arts at Florida State University and her evocative storytelling has spanned the mediums of narrative film, music video and virtual reality.
          <br />    <br />

          She is currently developing a limited series, <i>The Beautiful Future</i> with the Oscar winning Chilean production company Fabula, and a second narrative feature, <i>Witches of the American Southeast</i>.

          <br />
          <p><a href="http://screenshotsfrommycomputer.tumblr.com/"target="_blank">SCREENSHOTS FROM MY COMPUTER</a></p>
          </div>

          <div className="AboutContact">

          <h3>Management</h3>
          Cinetic Media <br />
          <a href="mailto:steven@cineticmedia.com">steven@cineticmedia.com</a>

        </div>
      </div>
      <div className="Girl">
        <img src={girl1} />
      </div>
      
    
    </div>
  );
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: pages.VIDEOS
    }
    this.homeClick = this.homeClick.bind(this);
    this.aboutClick = this.aboutClick.bind(this);
  }

  homeClick(e) {
    e.preventDefault();
    this.setState(state => ({
      currentPage: pages.VIDEOS
    }));
  }

  aboutClick(e) {
    e.preventDefault();
    document.body.style.backgroundImage = null;
    this.setState(state => ({
      currentPage: pages.ABOUT
    }));
  }

  render() {
    let currentPage = <Videos/>;
    switch(this.state.currentPage) {
      case pages.VIDEOS:
        currentPage = <Videos/>;
        break;
      case pages.ABOUT:
        currentPage = <About/>;
        break;
      default:
        currentPage = <Videos/>
        break;
    }
    return (
      <div className="App">
      <header className="App-header">
        <div className="Header-links">
          <div className="Header-link">
            <a href="#" onClick={this.homeClick}>Index</a>
            </div>
            <div className="Header-dash">
              /
            </div>
          <div className="Header-link">
            <a href={'https://dallasdoesntloveyou.squarespace.com'} target="_blank">Photos</a>
          </div>
          <div className="Header-dash">
              /
            </div>
          <div className="Header-link-end">
            <a href="#" onClick={this.aboutClick}>About</a>
            </div>
        </div>
      </header>
      <div className="App-body">
        {currentPage}
      </div>
      
      
    </div>
    );
  }
}

export default App;
